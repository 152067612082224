import { useMemo, useEffect, useCallback } from "react";
import { Stack, TooltipHost, TooltipDelay, DirectionalHint } from "@fluentui/react";

import styles from "./Answer.module.css";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";

import { FiFileText } from "react-icons/fi";
import { BsDatabase } from "react-icons/bs";
import { FaQuestionCircle, FaPauseCircle, FaPlayCircle } from "react-icons/fa";

import { AnswerIcon } from "./AnswerIcon";
import { parseAnswerToHtml } from "./AnswerParser";

import ChartRenderer from "./../ChartRenderer/ChartRenderer";
import FeedbackComponent from "../FeedbackComponent/FeedbackComponent";
import SimpleSQLFormatter from "./SimpleSQLFormatter";

import { ChatAppResponse } from "../../api";
import { gselectedgroup, gfeedback, gcaption } from "../../interfaces";
import { convertMarkdownHeadersToHTML, copyToClipboard, extractTextCptns, isTouchDevice } from "../../util_glob";

interface CustomElement extends HTMLElement {
    listenerAdded?: boolean;
}

type DataPoint = {
    content: string;
    filename: string;
    pages: any[];
    fileid: string;
};

interface speakProps {
    isSpeaking: boolean;
    isPaused: boolean | null;
}
interface Props {
    index: number;
    answer: ChatAppResponse;
    isLoading?: boolean;
    isStreaming: boolean;
    speakStatus: speakProps;
    selectedGroup: gselectedgroup;
    showFollowupQuestions?: boolean;
    clickedCitation: string;
    totalAnswers: number;
    isSBS?: boolean;
    isReverseAnswerList?: boolean;
    onCitationClicked: (filePath: string, integratedCitation: string | null, el: HTMLElement | null, openSBS?: boolean) => void;
    onFollowupQuestionClicked?: (question: string) => void;
    onSendFeedback: (prps: gfeedback) => void;
    manageSpeechPause: (val: boolean) => void;
}

export const Answer = ({
    index,
    answer,
    isLoading,
    isStreaming,
    speakStatus,
    selectedGroup,
    showFollowupQuestions,
    clickedCitation,
    totalAnswers,
    isSBS,
    isReverseAnswerList,
    onSendFeedback,
    onCitationClicked,
    onFollowupQuestionClicked,
    manageSpeechPause
}: Props) => {
    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const citationData = answer.choices[0].context.data_points_integrated?.citations || [];
    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(messageContent, isStreaming, citationData, index, clickedCitation, selectedGroup.assistanttype),
        [answer]
    );

    const dataPoints = useMemo(() => {
        let points: gcaption[] = [];
        let citD = answer.choices[0].context.data_points_integrated?.citations || [];
        citD?.map((x: any) => {
            points.push({
                content: x.content,
                filename: x.filepath.split("#page=")[0],
                page: `${x.filepath.split("#page=")[1]}`,
                fileid: x.url,
                chunkid: x.chunk_id
            });
        });
        const uniqueDataPoints: { [key: string]: DataPoint } = {};
        points = points.sort((a, b) => (a.chunkid || 0) - (b.chunkid || 0));
        points.forEach(point => {
            if (uniqueDataPoints[point.filename]) {
                uniqueDataPoints[point.filename].pages.push(point);
            } else {
                uniqueDataPoints[point.filename] = { content: point.content, filename: point.filename, pages: [point], fileid: point.fileid };
            }
        });
        return Object.values(uniqueDataPoints);
    }, [answer.choices[0].context.data_points, answer.choices[0].context.data_points_integrated]);

    const chart = useMemo(() => {
        try {
            return answer.choices[0].context.chartjs_json && <ChartRenderer configJson={JSON.stringify(answer.choices[0].context.chartjs_json)} />;
        } catch (e) {
            return null;
        }
    }, [answer.choices[0].context.chartjs_json]);

    const handleClick = useCallback(
        (event: MouseEvent) => {
            const openCitationFromMessage = (el: HTMLElement, openSBS?: boolean) => {
                const citationPath = el.getAttribute("data-citation-link");
                const integratedCitation = el.getAttribute("data-integrated-citation");

                if (citationPath) onCitationClicked(citationPath, integratedCitation, el, openSBS);
                const tooltip = document.getElementById("tooltip");
                if (tooltip) {
                    tooltip.classList.remove("visible");
                    setTimeout(() => {
                        tooltip.remove();
                    }, 100);
                }
            };

            const target = event.target as HTMLElement;
            if (target.matches(".citation-link")) {
                openCitationFromMessage(target);
            } else if (target.matches(".citation-tooltip-link")) {
                const relevantElement = target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector("sup") as HTMLElement;
                openCitationFromMessage(relevantElement);
            } else if (target.matches("span")) {
                if (target.innerText?.toLowerCase() == "see in document") {
                    const relevantElement = target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector(
                        "sup"
                    ) as HTMLElement;
                    openCitationFromMessage(relevantElement, false);
                } else if (target.innerText?.toLowerCase() == "explore in side-by-side view") {
                    const relevantElement = target.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector(
                        "sup"
                    ) as HTMLElement;
                    openCitationFromMessage(relevantElement, true);
                }
            }
        },
        [onCitationClicked]
    );

    useEffect(() => {
        if (isStreaming) return;
        const container = document.getElementById(`answr_cntnt_${index}`) as CustomElement;
        if (container && !container.listenerAdded) {
            container.listenerAdded = true;
            container.addEventListener("click", handleClick);
            tippy("[data-tippy-content]", {
                placement: "auto",
                arrow: true,
                animation: "shift-away",
                theme: "custom",
                allowHTML: true,
                interactive: true,
                duration: [300, 250],
                offset: [0, 10]
            });
        }
    }, [isStreaming]);

    return (
        <Stack id={`answr_${index}`} className={`${styles.answerContainer}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between" className={styles.topHorizontalStack}>
                    <Stack.Item>
                        <AnswerIcon />
                    </Stack.Item>
                    <Stack.Item className={styles.topRightAnswerWrap}>
                        {!isStreaming && answer?.choices[0]?.context?.sql_query && (
                            <div className={`${index == totalAnswers - 1 ? styles.answerInfoRight : styles.answerInfoTotallyRight}`}>
                                <div
                                    onClick={e => {
                                        copyToClipboard(answer?.choices[0]?.context?.sql_query || "");
                                    }}
                                    className={styles.sqlQueryLabel}
                                >
                                    <TooltipHost
                                        closeDelay={500}
                                        content={
                                            <div>
                                                <div className={styles.sqltooltip}>{SimpleSQLFormatter(answer?.choices[0]?.context?.sql_query || "")}</div>
                                            </div>
                                        }
                                        directionalHint={DirectionalHint.topCenter}
                                        delay={isTouchDevice() ? TooltipDelay.long : TooltipDelay.zero}
                                    >
                                        <BsDatabase size={15}></BsDatabase>
                                    </TooltipHost>
                                </div>
                            </div>
                        )}
                        {!isStreaming && index == totalAnswers - 1 ? (
                            <div className={styles.feedbackContainer}>
                                {speakStatus.isSpeaking == true || speakStatus.isPaused == true ? (
                                    speakStatus.isPaused == false || speakStatus.isPaused == null ? (
                                        <div onClick={() => manageSpeechPause(true)} className={styles.speechControls}>
                                            <FaPauseCircle size={20} />
                                        </div>
                                    ) : (
                                        <div onClick={() => manageSpeechPause(false)} className={styles.speechControls}>
                                            <FaPlayCircle size={20} />
                                        </div>
                                    )
                                ) : null}
                                <FeedbackComponent onSendFeedback={onSendFeedback} interaction_guid={answer.interaction_guid} pos={answer?.pos} />
                            </div>
                        ) : isStreaming && isLoading == false && index == totalAnswers - 1 ? (
                            <div className={styles.streamingLabel}>
                                <div className={styles.loader}>
                                    <div className={styles.duo + " " + styles.duo1}>
                                        <div className={styles.dot + " " + styles.dota}></div>
                                        <div className={styles.dot + " " + styles.dotb}></div>
                                    </div>
                                    <div className={styles.duo + " " + styles.duo2}>
                                        <div className={styles.dot + " " + styles.dota}></div>
                                        <div className={styles.dot + " " + styles.dotb}></div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            {!!dataPoints.length && selectedGroup.inscope === 1 && selectedGroup.hideaisearch != 1 && (
                <Stack.Item>
                    <div className={styles.searchResultsLabel}>
                        <div>AI Search Results:</div>
                        <div className={styles.askMoreIcon}>
                            <TooltipHost
                                content="All Content considered by the Assistant to answer you query. The answer might not use all of these sources."
                                directionalHint={DirectionalHint.topCenter}
                            >
                                <FaQuestionCircle size={10}></FaQuestionCircle>
                            </TooltipHost>
                        </div>
                    </div>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        {dataPoints.map((x, i) => (
                            <Stack.Item key={`aisrchres_${x.fileid || "nofleid"}_${i}_${index}`} className={styles.sourceDoc}>
                                <div className={styles.citationIcon}>
                                    <FiFileText size={16} />
                                </div>
                                <div className={styles.citationFileText}>{`${x.filename}`}</div>

                                {x.pages.length > 0 ? (
                                    x.pages.map((page, subindex) => {
                                        const title = `/content/${x.filename}#page=${page.page}_fid=${x.fileid}`;
                                        return (
                                            <a
                                                key={`${"airesitm"}_${x.fileid}${subindex}`}
                                                onClick={e => {
                                                    onCitationClicked(title, null, null);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <TooltipHost
                                                    className={styles.srchToolDocTooltip}
                                                    content={
                                                        <div className={styles.srchToolMain}>
                                                            <div className={styles.srchToolDocText}>
                                                                <div className={styles.srchToolDocTitle}>{x.filename}</div>
                                                                <div className={styles.srchToolDocDetails}>
                                                                    <div className={styles.srchToolDocPage}> Page Range {page.page.replace("|", "..")}</div>
                                                                    <div className={styles.srchToolDivider}>·</div>
                                                                    <div>Chunk ID {page.chunkid}</div>
                                                                </div>
                                                            </div>
                                                            <div>{page.content}</div>
                                                        </div>
                                                    }
                                                    directionalHint={DirectionalHint.topCenter}
                                                    delay={isTouchDevice() ? TooltipDelay.long : TooltipDelay.zero}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        return;
                                                    }}
                                                >
                                                    <div className={styles.docSourceCitation} key={`${"airessub"}_${i}_${subindex}`}>
                                                        {page.page.replace("|", "..")}
                                                    </div>
                                                </TooltipHost>
                                            </a>
                                        );
                                    })
                                ) : (
                                    <div></div>
                                )}
                            </Stack.Item>
                        ))}
                    </Stack>
                </Stack.Item>
            )}

            <Stack.Item grow>
                <div id={`answr_cntnt_${index}`} className={styles.answerText} dangerouslySetInnerHTML={{ __html: parsedAnswer.answerHtml }}></div>
            </Stack.Item>

            {answer.choices[0]?.context?.no_info_message && (
                <Stack.Item className={styles.externalInfoStackItem}>
                    <div className={styles.searchResultsLabel}>
                        <div className={styles.citationLearnMore}>External Info & Clarifications:</div>
                        <div className={styles.externalInfoMoreIcon}>
                            <TooltipHost
                                content="Any content under External Info & Clarifications is based on general AI knowlege."
                                directionalHint={DirectionalHint.topCenter}
                            >
                                <FaQuestionCircle size={10}></FaQuestionCircle>
                            </TooltipHost>
                        </div>
                    </div>
                    <div className={styles.noInfoMessage}>
                        <div
                            className={styles.answerText}
                            dangerouslySetInnerHTML={{ __html: convertMarkdownHeadersToHTML(answer.choices[0].context.no_info_message) }}
                        ></div>
                    </div>
                </Stack.Item>
            )}

            {!!parsedAnswer.citations.length && !selectedGroup.disablecitationhighlighting && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }} className={styles.citationStack}>
                        <div className={styles.citationsLabel}>
                            <div className={styles.citationLearnMore}>Citations:</div>
                            <div className={styles.askMoreIconCitations}>
                                <TooltipHost
                                    content="Content used by the Assistant to anwser your query. Click to navigate to the Page of the document. For more precise sourcing use inline citations"
                                    directionalHint={DirectionalHint.topCenter}
                                >
                                    <FaQuestionCircle size={10}></FaQuestionCircle>
                                </TooltipHost>
                            </div>
                        </div>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = `/content/${x}`;
                            const citInf = extractTextCptns(x);
                            return (
                                <a
                                    key={i}
                                    id={`cit_answid_${index}_citinx_${i}`}
                                    className={isSBS == true ? styles.citationQSBS : styles.citationQ}
                                    title={x}
                                    onClick={() => onCitationClicked(path, null, null)}
                                >
                                    <div className={styles.citationIcon}>
                                        <FiFileText size={16} />
                                    </div>
                                    {`${++i}. ${citInf?.filename} [${citInf?.page}]`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && index == totalAnswers - 1 && (
                <Stack.Item className={styles.externalInfoStackItem}>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {chart}
        </Stack>
    );
};
